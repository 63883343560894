<!-- @format -->

<template>
  <div class="auth-wrapper auth-v2">
    <!-- {{ pid }} -->
    <!-- Brand logo-->
    <b-link class="brand-logo" :to="{ name: 'auth-login' }">
      <!-- <vuexy-logo /> -->
      <img src="@/assets/images/logo/logo.png" alt="">
      <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
    </b-link>
    <!-- /Brand logo-->

    <!-- Register-->
    <div class="d-flex align-items-center auth-form">
      <b-card-title class="mb-1"> Welcome !! 🚀 </b-card-title>
      <b-card-text class="mb-2">
        You Can Register your account here!
      </b-card-text>
      <!-- form -->
      <validation-observer ref="registerForm" style="width: 100%">
        <b-form class="auth-register-form mt-2" @submit.prevent="register">
          <!-- username -->
          <b-form-group label="First Name (or Company Name)" label-for="register-username">
            <validation-provider
              #default="{ errors }"
              name="real_name"
              vid="real_name"
              rules="required"
            >
              <b-form-input
                v-model="firstname"
                name="register-real_name"
                :state="errors.length > 0 ? false : null"
                placeholder="First Name"
              />
              <small class="text-danger">{{ errors[0] ? 'First Name (or Company Name) is required' : errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label=" Last Name (or DBA Name)" label-for="register-username">
            <validation-provider
              #default="{ errors }"
              name="firstname"
              vid="firstname"
              rules="required"
            >
              <b-form-input
                v-model="lastname"
                name="register-real_name"
                :state="errors.length > 0 ? false : null"
                placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] ? 'Last Name (or DBA Name) is required' : errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- email -->
          <b-form-group label="Mobile phone number" label-for="register-mobile">
            <validation-provider
              #default="{ errors }"
              name="mobile"
              vid="mobile"
            >
              <!-- 验证只能输入数字 -->
              <b-form-input
                id="register-mobile"
                v-model="mobile"
                name="register-mobile"
                placeholder="Mobile phone number"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Date of birth" label-for="birth">
            <validation-provider
              #default="{ errors }"
              name="birth"
              vid="birth"
            >
              <!-- <flat-pickr
                v-model="birth"
                class="form-control invoice-edit-input"
                placeholder="Date of birth"
              /> -->
              <div id="myDate" style="display: flex;">
                <v-select
                  v-model="yearsModel"
                  :clearable="false"
                  :options="years"
                  :reduce="option => option.value"
                  placeholder="Year"
                  style="width: 125px; margin-right: 12px"
                  @change="dateChange(1)"
                />
                <v-select
                  v-model="monthsModel"
                  :clearable="false"
                  :options="months"
                  :reduce="option => option.value"
                  placeholder="Month"
                  style="width: 125px; margin-right: 12px"
                  @change="dateChange(2)"
                />
                <v-select
                  v-model="daysModel"
                  :clearable="false"
                  :options="days"
                  :reduce="option => option.value"
                  placeholder="Date"
                  style="width: 125px;"
                  @change="dateChange(3)"
                />
              </div>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Countries" label-for="register-country">
            <validation-provider
              #default="{ errors }"
              name="country"
              vid="country"
            >
              <!-- <b-form-input
                    id="register-country"
                    v-model="country"
                    placeholder="countries"
                    name="register-country"
                  /> -->
              <!-- 下拉框 -->
              <!-- 只有两个选项  US CA-->

              <b-form-select
                id="register-country"
                v-model="country"
                placeholder="Choose your Country"
                :options="[
                  { value: 'US', text: 'US' },
                  { value: 'CANADA', text: 'CANADA' },
                ]"
                name="register-country"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label=" Address" label-for="register-address">
            <validation-provider
              #default="{ errors }"
              name="address"
              vid="address"
            >
              <b-form-input
                id="register-address"
                v-model="address"
                name="register-address"
                placeholder="Home address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- 新增的玩意 -->

          <b-form-group label="Address 2 or APT#" label-for="register-address2">
            <validation-provider
              #default="{ errors }"
              name="address2"
              vid="address2"
            >
              <b-form-input
                id="register-address2"
                v-model="address2"
                name="register-address2"
                placeholder="Home Address 2"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="City" label-for="register-city">
            <validation-provider
              #default="{ errors }"
              name="city"
              vid="city"
            >
              <b-form-input
                id="register-city"
                v-model="city"
                name="register-city"
                placeholder="City"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="State" label-for="register-state">
            <validation-provider
              #default="{ errors }"
              name="state"
              vid="state"
            >
              <b-form-input
                id="register-state"
                v-model="state"
                name="register-state"
                placeholder="State"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Zipcode" label-for="register-post_code">
            <validation-provider
              #default="{ errors }"
              name="post_code"
              vid="post_code"
            >
              <b-form-input
                id="register-post_code"
                v-model="post_code"
                name="register-post_code"
                placeholder="Zipcode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- <b-form-group label="性别" label-for="register-gender">
                <validation-provider
                  #default="{ errors }"
                  name="gender"
                  vid="gender"
                >
                  <v-select
                    v-model="gender"
                    :placeholder="'请选择性别'"
                    :options="sex"
                    :options-label="'label'"
                    :options-value="'value'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
          <b-form-group label="Sponsor ID" label-for="register-pid">
            <b-form-input
              v-if="!isYaoq"
              v-model="pid"
              placeholder="Invite code"
              :disabled="isYaoq"
            />
            <div
              v-else
              style="
                    border: 1px solid #f5f5f5;
                    width: 100%;
                    height: 38px;
                    line-height: 38px;
                    color: #ccc;
                    padding-left: 5px;
                    border-radius: 6px;
                  "
            >
              {{ pid }}
            </div>
          </b-form-group>
          <!-- password -->
          <b-form-group label-for="register-password_hash" label="Password">
            <validation-provider
              #default="{ errors }"
              name="password_hash"
              vid="password_hash"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-password_hash"
                  v-model="password_hash"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false : null"
                  name="register-password_hash"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email" label-for="register-email">
            <validation-provider
              #default="{ errors }"
              name="email"
              vid="email"
              rules="required"
              style="display: flex; justify-content: space-between"
            >
              <b-form-input
                id="register-email"
                v-model="email"
                name="register-email"
                :state="errors.length > 0 ? false : null"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <!-- <b-button
                v-if="text === 'send'"
                variant="primary"
                style="width: 24%"
                :disabled="disable"
                @click="fs_email"
              >
                {{ text }}
              </b-button>

              <b-button
                v-if="text !== 'send'"
                style="width: 24%"
                :disabled="disable"
                @click="fs_email"
              >
                {{ text }}
              </b-button> -->
            </validation-provider>
          </b-form-group>

          <!-- <b-form-group label="Verify code" label-for="register-check_code">
            <validation-provider
              #default="{ errors }"
              name="check_code"
              vid="check_code"
              rules="required"
            >
              <b-form-input
                id="register-check_code"
                v-model="check_code"
                name="register-check_code"
                :state="errors.length > 0 ? false : null"
                placeholder="Verify code"
              />
            </validation-provider>
          </b-form-group> -->

          <!-- 返回登录 -->
          <b-card-text class="text-center mt-2">
            <span>Existing account? </span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Cick here to login</span>
            </b-link>
          </b-card-text>

          <b-button 
  variant="primary" 
  block 
  type="submit" 
  :disabled="isRegistering"
  @click="debouncedRegister">
  Confirm to submit
</b-button>
        </b-form>
      </validation-observer>
    </div>
    <!-- /Register-->
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    VuexyLogo,
    BFormSelect,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isYaoq: false,
      status: '',
      fs_status: 'false',
      real_name: '',
      address: '',
      birth: '',
      rangePicker: ['2019-05-01', '2019-05-10'],
      gender: 0,
      pid: '',
      text: 'Send',
      time: 60,
      timer: null,
      disable: false,
      password_hash: '',
      mobile: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email: '',
      country: '',
      num: 60,
      check_code: '',
      lastname: '',
      name: '',
      firstname: '',
      address2: '',
      state: '',
      city: '',
      post_code: '',
      sex: [
        {
          label: '男',
          value: 1,
        },
        {
          label: '女',
          value: 2,
        },
      ],
      tuijian: [
        {
          label: '1号',
          value: 1,
        },
        {
          label: '2号',
          value: 2,
        },
      ],

      yearsModel: '',
      years: [],
      monthsModel: null,
      months: [],
      daysModel: null,
      days: [],
      isRegistering: false,
      registerTimeout: 10, // 10秒倒计时
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    const time = localStorage.getItem('time')
    // 判断是否大于0
    if (time && time > 0) {
      //  解决刷新页面按钮有空白情况
      this.text = `${time}秒`
      this.time = time
      this.send()
    }
    this.initDateSelect()
  },
  mounted() {
    // 获取地址栏参数,以对象的形式返回
    function GetQueryString(name = 'code') {
      const url = window.location.href
      // 去除地址栏中的空格,防止出现空格导致获取不到参数,%20是空格的编码
      const str = url.replace(/\s/g, '')
      // 替换%20为空格
      const newStr = str.replace(/%20/g, '')
      const theRequest = {}
      if (newStr.indexOf('?') !== -1) {
        const strs = newStr.split('?')[1].split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
        }
      }
      return theRequest[name]
    }

    this.$nextTick(() => {
      // 获取地址栏参数中是否含有 from
      const url = window.location.href
      // 去除地址栏中的空格
      const url1 = url.replace(/\s+/g, '')

      const index = url1.indexOf('?')

      if (index > -1) {
        if (GetQueryString('from')) {
          console.log('有from', GetQueryString('code'))
          setTimeout(() => {
            this.pid = GetQueryString('code')
            this.$set(this, 'pid', GetQueryString('code'))
            this.$forceUpdate()
          }, 1000)
          this.isYaoq = true
        }
      }
    })
  },
  methods: {
    initDateSelect() {
      const myDate = new Date()
      const year = myDate.getFullYear()// 获取当前年
      const month = myDate.getMonth() + 1// 获取当前月
      const day = myDate.getDate()// 获取当前日

      this.initSelectYear(year)
      this.initSelectMonth()
      this.initSelectDay(year, month)
      // this.yearsModel = year
      // this.monthsModel = month
      // this.daysModel = day
    },
    initSelectYear(year) {
      this.years = []
      for (let i = 0; i < 124; i++) {
        this.years.push({ value: (year - i), label: `${year - i}` })
      }
    },
    initSelectMonth() {
      this.months = []
      // this.months.push({ value: 0, label: '全部' })
      for (let i = 1; i <= 12; i++) {
        this.months.push({ value: i, label: `${i}` })
      }
    },
    initSelectDay(year, month) {
      const maxDay = this.getMaxDay(year, month)
      this.days = []
      // this.days.push({ value: 0, label: '全部' })
      for (let i = 1; i <= 31; i++) {
        this.days.push({ value: i, label: `${i}` })
      }
    },
    getMaxDay(year, month) {
      let new_year = year // 取当前的年份
      let new_month = month++// 取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) // 如果当前大于12月，则年份转到下一年
      {
        new_month -= 12 // 月份减
        new_year++ // 年份增
      }
      const new_date = new Date(new_year, new_month, 1)// 取当年当月中的第一天
      return (new Date(new_date.getTime() - 1000 * 60 * 60 * 24)).getDate()// 获取当月最后一天日期
    },
    dateChange(type) {
      // 1年 2月 3日 4 左 5右
      if (type == 1 || type == 2) {
        if (this.monthsModel == 0) {
          this.daysModel = 0
          this.initSelectDay(this.yearsModel, 1)
        } else {
          this.initSelectDay(this.yearsModel, this.monthsModel)
        }
      }

      // 操作父组件方法
      this.birth = `${this.yearsModel}-${this.monthsModel}-${this.daysModel}`
      console.log('obj', obj,)
      // this.$parent.dateChange(obj)
    },

    send() {
      // 点击按钮，禁用按钮，防止多次点击
      this.disable = true
      this.text = `${this.time}s`
      localStorage.setItem('time', this.time)
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
          localStorage.setItem('time', this.time)
          this.text = `${this.time}s`
        } else {
          clearInterval(this.timer)
          this.time = 60
          this.disable = false
          this.text = 'Send'
        }
      }, 1000)
    },

    fs_email() {
      console.log(this.firstname, this.lastname)
      if (this.firstname == '' && this.lastname == '') {
        this.$toast('Please fill in your name first')
      } if (this.pid == '') {
        this.$toast('Please fill in Sponsor ID first')
      } else {
        this.name = this.firstname + this.lastname
        useJwt
          .fs_email({ email: this.email, firstname: this.name, pid: this.pid })
          .then(response => {
            console.log(response)
            if (response.code == 0) {
              this.send()
            } else {
              this.$toast('Wrong email address')
            }
          })
      }
    },
    debouncedRegister() {
  if (this.isRegistering) return;

  this.isRegistering = true; // 禁用按钮
  this.register(); // 立即调用注册方法

  // 设置计时器，在10秒后重新启用按钮
  setTimeout(() => {
    this.isRegistering = false;
  }, 10000); // 10秒后执行
},


    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt
            .register({
              email: this.email,
              mobile: this.mobile,
              // real_name: this.real_name,
              firstname: this.firstname,
              lastname: this.lastname,
              country: this.country,
              birth: `${this.yearsModel}-${this.monthsModel}-${this.daysModel}`,
              address: this.address,
              gender: this.gender.value,
              password_hash: this.password_hash,
              pid: this.pid,
              check_code: this.check_code,
              address2: this.address2,
              state: this.state,
              city: this.city,
              post_code: this.post_code,
            })
            .then(response => {
              if (response.code == 0) {
                this.$toast('Register successfully')
                setTimeout(this.$router.push('/login'), 2000) // 返回login
              } else if (response.code == -1) {
                this.$toast(response.msg)
              }
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.xz_birth {
  width: 100%;
  height: 38px;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.auth-form {
  width: 400px;
  flex-direction: column;
  margin: 160px auto 80px;
}
@media (max-width: 576px){
 .auth-form {
    width: 80vw;
  }
}
.form-birth {
  width: 125px;
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
</style>
