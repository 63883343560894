var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":{ name: 'auth-login' }}},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":""}})]),_c('div',{staticClass:"d-flex align-items-center auth-form"},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome !! 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" You Can Register your account here! ")]),_c('validation-observer',{ref:"registerForm",staticStyle:{"width":"100%"}},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"First Name (or Company Name)","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"real_name","vid":"real_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"register-real_name","state":errors.length > 0 ? false : null,"placeholder":"First Name"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'First Name (or Company Name) is required' : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":" Last Name (or DBA Name)","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"firstname","vid":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"register-real_name","state":errors.length > 0 ? false : null,"placeholder":"Last Name"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? 'Last Name (or DBA Name) is required' : errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Mobile phone number","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"mobile","vid":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-mobile","name":"register-mobile","placeholder":"Mobile phone number","type":"number"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Date of birth","label-for":"birth"}},[_c('validation-provider',{attrs:{"name":"birth","vid":"birth"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"display":"flex"},attrs:{"id":"myDate"}},[_c('v-select',{staticStyle:{"width":"125px","margin-right":"12px"},attrs:{"clearable":false,"options":_vm.years,"reduce":function (option) { return option.value; },"placeholder":"Year"},on:{"change":function($event){return _vm.dateChange(1)}},model:{value:(_vm.yearsModel),callback:function ($$v) {_vm.yearsModel=$$v},expression:"yearsModel"}}),_c('v-select',{staticStyle:{"width":"125px","margin-right":"12px"},attrs:{"clearable":false,"options":_vm.months,"reduce":function (option) { return option.value; },"placeholder":"Month"},on:{"change":function($event){return _vm.dateChange(2)}},model:{value:(_vm.monthsModel),callback:function ($$v) {_vm.monthsModel=$$v},expression:"monthsModel"}}),_c('v-select',{staticStyle:{"width":"125px"},attrs:{"clearable":false,"options":_vm.days,"reduce":function (option) { return option.value; },"placeholder":"Date"},on:{"change":function($event){return _vm.dateChange(3)}},model:{value:(_vm.daysModel),callback:function ($$v) {_vm.daysModel=$$v},expression:"daysModel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Countries","label-for":"register-country"}},[_c('validation-provider',{attrs:{"name":"country","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"register-country","placeholder":"Choose your Country","options":[
                  { value: 'US', text: 'US' },
                  { value: 'CANADA', text: 'CANADA' } ],"name":"register-country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":" Address","label-for":"register-address"}},[_c('validation-provider',{attrs:{"name":"address","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address","name":"register-address","placeholder":"Home address"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Address 2 or APT#","label-for":"register-address2"}},[_c('validation-provider',{attrs:{"name":"address2","vid":"address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address2","name":"register-address2","placeholder":"Home Address 2"},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"City","label-for":"register-city"}},[_c('validation-provider',{attrs:{"name":"city","vid":"city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-city","name":"register-city","placeholder":"City"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"State","label-for":"register-state"}},[_c('validation-provider',{attrs:{"name":"state","vid":"state"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-state","name":"register-state","placeholder":"State"},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Zipcode","label-for":"register-post_code"}},[_c('validation-provider',{attrs:{"name":"post_code","vid":"post_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-post_code","name":"register-post_code","placeholder":"Zipcode"},model:{value:(_vm.post_code),callback:function ($$v) {_vm.post_code=$$v},expression:"post_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Sponsor ID","label-for":"register-pid"}},[(!_vm.isYaoq)?_c('b-form-input',{attrs:{"placeholder":"Invite code","disabled":_vm.isYaoq},model:{value:(_vm.pid),callback:function ($$v) {_vm.pid=$$v},expression:"pid"}}):_c('div',{staticStyle:{"border":"1px solid #f5f5f5","width":"100%","height":"38px","line-height":"38px","color":"#ccc","padding-left":"5px","border-radius":"6px"}},[_vm._v(" "+_vm._s(_vm.pid)+" ")])],1),_c('b-form-group',{attrs:{"label-for":"register-password_hash","label":"Password"}},[_c('validation-provider',{attrs:{"name":"password_hash","vid":"password_hash"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password_hash","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password_hash","placeholder":"Password"},model:{value:(_vm.password_hash),callback:function ($$v) {_vm.password_hash=$$v},expression:"password_hash"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"name":"email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Existing account? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" Cick here to login")])])],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isRegistering},on:{"click":_vm.debouncedRegister}},[_vm._v(" Confirm to submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }